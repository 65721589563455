import React from 'react';
import { useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { nb } from 'date-fns/locale';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './TemperatureDetail.css';

// Fix for default marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

function TemperatureDetail({ temperatures, unit }) {
  const { locationId } = useParams();

  const locationData = temperatures.find(temp => temp.locationId === locationId);

  const convertTemperature = temp => {
    if (unit === 'F') {
      return ((temp * 9) / 5 + 32).toFixed(1);
    }
    return temp.toFixed(1);
  };

  const formatDate = date => {
    if (date) {
      return format(parseISO(date), 'PPpp', { locale: nb });
    }
    return 'N/A';
  };

  if (!locationData) return <div>No data available for this location.</div>;

  return (
    <div className="temperature-detail">
      <h2>{locationData.locationName || 'Unknown Location'}</h2>
      <div className="detail-container">
        <div className="detail-card">
          <div className="detail-item">
            <span className="detail-label">Temperature:</span>
            <span className="detail-value temperature">
              {convertTemperature(locationData.temperature)}°{unit}
            </span>
          </div>
          <div className="detail-item">
            <span className="detail-label">County:</span>
            <span className="detail-value">{locationData.county || 'N/A'}</span>
          </div>
          <div className="detail-item">
            <span className="detail-label">Municipality:</span>
            <span className="detail-value">{locationData.municipality || 'N/A'}</span>
          </div>

          <div className="detail-item">
            <span className="detail-label">Last updated:</span>
            <span className="detail-value">{formatDate(locationData.time)}</span>
          </div>
        </div>
        <div className="map-container">
          <MapContainer
            center={[locationData.position.lat, locationData.position.lon]}
            zoom={13}
            style={{ height: '400px', width: '100%' }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={[locationData.position.lat, locationData.position.lon]}>
              <Popup>
                {locationData.locationName}
                <br />
                Temperature: {convertTemperature(locationData.temperature)}°{unit}
              </Popup>
            </Marker>
          </MapContainer>
        </div>
      </div>
    </div>
  );
}

export default TemperatureDetail;
