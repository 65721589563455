import React from 'react';
import { Link } from 'react-router-dom';
import './TemperatureList.css';

function FavoritesList({ temperatures, favorites, unit, toggleFavorite }) {
  const favoriteTemperatures = temperatures.filter(temp => favorites.includes(temp.locationId));

  const convertTemperature = temp => {
    if (unit === 'F') {
      return ((temp * 9) / 5 + 32).toFixed(1);
    }
    return temp.toFixed(1);
  };

  const getWeatherIcon = temp => {
    if (temp < 10) return '❄️';
    if (temp < 20) return '🌤️';
    return '☀️';
  };

  const getTemperatureColor = temp => {
    if (temp < 10) return 'cold';
    if (temp < 20) return 'moderate';
    return 'warm';
  };

  return (
    <div className="favorites-list">
      <div className="card-container">
        {favoriteTemperatures.map(temp => (
          <div key={temp.locationId} className={`card ${getTemperatureColor(temp.temperature)}`}>
            <h3>{temp.locationName}</h3>
            <p className="temperature">
              {getWeatherIcon(temp.temperature)} {convertTemperature(temp.temperature)}°{unit}
            </p>
            <p>Last updated: {temp.time ? new Date(temp.time).toLocaleString() : 'N/A'}</p>
            <button
              onClick={() => toggleFavorite(temp.locationId)}
              className="favorite-btn is-favorite"
              aria-label="Remove from favorites"
            >
              ❤️
            </button>
            <Link to={`/location/${temp.locationId}`}>More details</Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FavoritesList;
