import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './MapView.css';
import L from 'leaflet';
import 'leaflet.locatecontrol';
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

function LocateControl() {
  const map = useMap();

  useEffect(() => {
    const locateControl = L.control
      .locate({
        position: 'topright',
        strings: {
          title: 'Show me where I am',
        },
        locateOptions: {
          enableHighAccuracy: true,
        },
      })
      .addTo(map);

    return () => {
      locateControl.remove();
    };
  }, [map]);

  return null;
}

function MapView({ temperatures }) {
  return (
    <MapContainer
      center={[62.0, 10.0]}
      zoom={5}
      className="map-container" /* Add the CSS class here */
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <LocateControl />
      {temperatures.map(temp => (
        <Marker key={temp.locationId} position={[temp.position.lat, temp.position.lon]}>
          <Popup>
            <h3>{temp.locationName}</h3>
            <p>Temperature: {temp.temperature}°C</p>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
}

export default MapView;
