import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,

  authDomain: 'badetemp-90868.firebaseapp.com',

  projectId: 'badetemp-90868',

  storageBucket: 'badetemp-90868.appspot.com',

  messagingSenderId: '170776849753',

  appId: '1:170776849753:web:8f0baf568f29becffa7048',

  measurementId: 'G-FTT5JFR0S2',
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
