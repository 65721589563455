import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../config/firebase';
import { doc, setDoc, getDoc, collection, getDocs, serverTimestamp } from 'firebase/firestore';
import ReactStars from 'react-rating-stars-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { format, parseISO } from 'date-fns';
import { nb } from 'date-fns/locale';
import './TemperatureList.css';

function TemperatureList({ temperatures, favorites, toggleFavorite, unit }) {
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [showOnlyFavorites] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [sortBy, setSortBy] = useState('name');
  const [isGettingLocation, setIsGettingLocation] = useState(false);
  const [ratings, setRatings] = useState({});
  const [userIP, setUserIP] = useState(null);
  const [temperatureSortOrder, setTemperatureSortOrder] = useState('none');

  const fetchRatings = useCallback(async () => {
    try {
      const ratingsCollection = collection(db, 'ratings');
      const ratingsSnapshot = await getDocs(ratingsCollection);
      const ratingsData = {};
      ratingsSnapshot.forEach(doc => {
        ratingsData[doc.id] = doc.data();
      });
      setRatings(ratingsData);
    } catch (error) {
      console.error('Error fetching ratings:', error);
      toast.error('Failed to fetch ratings. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchRatings();

    const fetchUserIP = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        setUserIP(data.ip);
      } catch (error) {
        console.error('Error fetching user IP:', error);
        toast.error('Unable to get user IP. Some features may be limited.');
      }
    };
    fetchUserIP();
  }, [fetchRatings]);

  const handleRatingChange = async (locationId, newRating) => {
    if (!userIP) {
      toast.error('Unable to submit rating. User IP not available.');
      return;
    }

    try {
      const ratingRef = doc(db, 'ratings', locationId);
      const ratingDoc = await getDoc(ratingRef);
      const currentRating = ratingDoc.data() || {};
      const userRatings = currentRating.userRatings || {};
      const userPreviousRating = userRatings[userIP] || {};

      if (userPreviousRating.rating === newRating) {
        toast.info('You have already submitted this rating.');
        return;
      }

      const lastRatedTime = userPreviousRating.timestamp?.toDate();
      const now = new Date();

      if (lastRatedTime && now - lastRatedTime > 24 * 60 * 60 * 1000) {
        toast.error('You can only change your rating within 24 hours of your last rating.');
        return;
      }

      const isNewRating = !userPreviousRating.rating;
      const ratingDifference = newRating - (userPreviousRating.rating || 0);

      const newRatingData = {
        lastUpdated: serverTimestamp(),
        ratingCount: (currentRating.ratingCount || 0) + (isNewRating ? 1 : 0),
        totalRating: (currentRating.totalRating || 0) + ratingDifference,
        userRatings: {
          ...userRatings,
          [userIP]: {
            rating: newRating,
            timestamp: serverTimestamp(),
          },
        },
      };

      await setDoc(ratingRef, newRatingData, { merge: true });

      setRatings(prevRatings => ({
        ...prevRatings,
        [locationId]: {
          ...prevRatings[locationId],
          ...newRatingData,
          lastUpdated: now,
        },
      }));
      toast.success('Rating submitted successfully!');
    } catch (error) {
      console.error('Error saving rating:', error);
      toast.error(`Failed to save rating: ${error.message}`);
    }
  };

  const getAverageRating = useCallback(
    locationId => {
      const locationRating = ratings[locationId];
      if (locationRating && locationRating.ratingCount > 0) {
        return locationRating.totalRating / locationRating.ratingCount;
      }
      return 0;
    },
    [ratings]
  );

  const formatDate = date => {
    if (date instanceof Date) {
      return format(date, 'PPpp', { locale: nb });
    } else if (date && date.seconds) {
      return format(new Date(date.seconds * 1000), 'PPpp', { locale: nb });
    }
    return 'N/A';
  };

  const getUserLocation = () => {
    if ('geolocation' in navigator) {
      setIsGettingLocation(true);
      navigator.geolocation.getCurrentPosition(
        position => {
          setUserLocation({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          });
          setSortBy('distance');
          setIsGettingLocation(false);
        },
        error => {
          toast.error(
            'Unable to retrieve your location. Please try again or check your browser settings.'
          );
          setSortBy('name');
          setIsGettingLocation(false);
        },
        { timeout: 10000, maximumAge: 60000 }
      );
    } else {
      toast.error('Geolocation is not supported by your browser');
      setSortBy('name');
    }
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  };

  const deg2rad = deg => {
    return deg * (Math.PI / 180);
  };

  const convertTemperature = temp => {
    if (unit === 'F') {
      return ((temp * 9) / 5 + 32).toFixed(1);
    }
    return temp.toFixed(1);
  };

  const getWeatherIcon = temp => {
    if (temp < 10) return '❄️';
    if (temp < 20) return '🌤️';
    return '☀️';
  };

  const getTemperatureColor = temp => {
    if (temp < 10) return 'cold';
    if (temp < 20) return 'moderate';
    return 'warm';
  };

  const handleSortChange = () => {
    if (sortBy === 'name') {
      getUserLocation();
    } else {
      setSortBy('name');
    }
  };

  const handleTemperatureSort = () => {
    if (temperatureSortOrder === 'none' || temperatureSortOrder === 'low-to-high') {
      setTemperatureSortOrder('high-to-low');
      setSortBy('temperature');
    } else {
      setTemperatureSortOrder('low-to-high');
      setSortBy('temperature');
    }
  };

  let filteredTemperatures = temperatures.filter(temp => {
    const searchLower = searchTerm.toLowerCase();
    return (
      ((temp.locationName && temp.locationName.toLowerCase().includes(searchLower)) ||
        (temp.county && temp.county.toLowerCase().includes(searchLower)) ||
        (temp.municipality && temp.municipality.toLowerCase().includes(searchLower))) &&
      (!showOnlyFavorites || favorites.includes(temp.locationId))
    );
  });

  if (sortBy === 'distance' && userLocation) {
    filteredTemperatures = filteredTemperatures
      .map(temp => ({
        ...temp,
        distance: calculateDistance(
          userLocation.lat,
          userLocation.lon,
          temp.position.lat,
          temp.position.lon
        ),
      }))
      .sort((a, b) => a.distance - b.distance);
  } else if (sortBy === 'temperature') {
    filteredTemperatures.sort((a, b) => {
      if (temperatureSortOrder === 'high-to-low') {
        return b.temperature - a.temperature;
      } else {
        return a.temperature - b.temperature;
      }
    });
  } else {
    filteredTemperatures.sort((a, b) => a.locationName.localeCompare(b.locationName));
  }

  if (loading) return <div>Loading...</div>;
  if (temperatures.length === 0) return <div>No temperature data available.</div>;

  return (
    <div className="temperature-list">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="controls">
        <input
          type="text"
          placeholder="Search locations, counties, or municipalities..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />

        <button onClick={handleSortChange} disabled={isGettingLocation}>
          {isGettingLocation
            ? 'Getting Location...'
            : sortBy === 'distance'
              ? 'Sort by Name'
              : 'Sort by Distance'}
        </button>
        <button onClick={handleTemperatureSort}>
          {temperatureSortOrder === 'none'
            ? 'Sort by Temperature'
            : temperatureSortOrder === 'high-to-low'
              ? 'Temperature: High to Low'
              : 'Temperature: Low to High'}
        </button>
      </div>
      <div className="card-container">
        {filteredTemperatures.map(temp => (
          <div key={temp.locationId} className={`card ${getTemperatureColor(temp.temperature)}`}>
            <h3>{temp.locationName || 'Unknown Location'}</h3>
            <p className="temperature">
              {getWeatherIcon(temp.temperature)} {convertTemperature(temp.temperature)}°{unit}
            </p>
            <p>County: {temp.county || 'N/A'}</p>
            <p>Municipality: {temp.municipality || 'N/A'}</p>
            {sortBy === 'distance' && (
              <p>Distance: {temp.distance ? `${temp.distance.toFixed(2)} km` : 'N/A'}</p>
            )}
            <p>Last updated: {temp.time ? formatDate(parseISO(temp.time)) : 'N/A'}</p>

            <div className="stars-container">
              <ReactStars
                count={5}
                onChange={newRating => handleRatingChange(temp.locationId, newRating)}
                size={24}
                isHalf={true}
                activeColor="#ffd700"
                value={getAverageRating(temp.locationId)}
                edit={true}
              />
            </div>
            <p>
              Average Rating: {getAverageRating(temp.locationId).toFixed(1)} (
              {ratings[temp.locationId]?.ratingCount || 0} ratings)
            </p>
            <p>Last rated: {formatDate(ratings[temp.locationId]?.lastUpdated)}</p>
            <button
              onClick={() => toggleFavorite(temp.locationId)}
              className={`favorite-btn ${favorites.includes(temp.locationId) ? 'is-favorite' : ''}`}
              aria-label={
                favorites.includes(temp.locationId) ? 'Remove from favorites' : 'Add to favorites'
              }
            >
              {favorites.includes(temp.locationId) ? '❤️' : '🤍'}
            </button>
            <Link to={`/location/${temp.locationId}`}>More details</Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TemperatureList;
