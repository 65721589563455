import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  const [policyContent, setPolicyContent] = useState('');

  useEffect(() => {
    fetch('/privacy-policy.md')
      .then(response => response.text())
      .then(text => {
        setPolicyContent(text);
      })
      .catch(error => console.error('Error fetching privacy policy:', error));
  }, []);

  return (
    <div className="privacy-policy">
      <ReactMarkdown>{policyContent}</ReactMarkdown>
    </div>
  );
}

export default PrivacyPolicy;
