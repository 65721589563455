import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import axios from 'axios';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './config/firebase';
import Header from './components/Header';
import Footer from './components/Footer';
import TemperatureList from './components/TemperatureList';
import TemperatureDetail from './components/TemperatureDetail';
import MapView from './components/MapView';
import FavoritesList from './components/FavoritesList';
import PrivacyPolicy from './components/PrivacyPolicy';
import './App.css';
import './Transitions.css';
import 'react-toastify/dist/ReactToastify.css';

function AnimatedRoutes({ temperatures, favorites, toggleFavorite, unit, initialRatings }) {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition key={location.pathname} classNames="fade" timeout={300}>
        <Routes location={location}>
          <Route
            path="/"
            element={
              <TemperatureList
                temperatures={temperatures}
                favorites={favorites}
                toggleFavorite={toggleFavorite}
                unit={unit}
                initialRatings={initialRatings}
              />
            }
          />
          <Route
            path="/location/:locationId"
            element={<TemperatureDetail temperatures={temperatures} unit={unit} />}
          />
          <Route path="/map" element={<MapView temperatures={temperatures} />} />
          <Route
            path="/favorites"
            element={
              <FavoritesList
                temperatures={temperatures}
                favorites={favorites}
                toggleFavorite={toggleFavorite}
                unit={unit}
              />
            }
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}

function App() {
  const [temperatures, setTemperatures] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [unit, setUnit] = useState('C');
  const [initialRatings, setInitialRatings] = useState({});

  useEffect(() => {
    const fetchTemperatures = async () => {
      try {
        const response = await axios.get('https://badetemperaturer.yr.no/api/watertemperatures', {
          headers: {
            apikey: 'd75743e1-c136-4e56-8c59-6a3a82cc52fe',
          },
        });
        setTemperatures(response.data);
      } catch (error) {
        console.error('Error fetching temperatures:', error);
      }
    };

    const fetchInitialRatings = async () => {
      try {
        const ratingsCollection = collection(db, 'ratings');
        const ratingsSnapshot = await getDocs(ratingsCollection);
        const ratingsData = {};
        ratingsSnapshot.forEach(doc => {
          ratingsData[doc.id] = doc.data();
        });
        setInitialRatings(ratingsData);
      } catch (error) {
        console.error('Error fetching initial ratings:', error);
      }
    };

    fetchTemperatures();
    fetchInitialRatings();

    const storedFavorites = localStorage.getItem('favorites');
    if (storedFavorites) {
      setFavorites(JSON.parse(storedFavorites));
    }
  }, []);

  const toggleFavorite = locationId => {
    setFavorites(prev => {
      const newFavorites = prev.includes(locationId)
        ? prev.filter(id => id !== locationId)
        : [...prev, locationId];
      localStorage.setItem('favorites', JSON.stringify(newFavorites));
      return newFavorites;
    });
  };

  const toggleUnit = () => {
    setUnit(unit === 'C' ? 'F' : 'C');
  };

  return (
    <Router>
      <div className="App">
        <Header />
        <nav>
          <ul>
            <li>
              <Link to="/">List View</Link>
            </li>
            <li>
              <Link to="/map">Map View</Link>
            </li>
            <li>
              <Link to="/favorites">Favorites</Link>
            </li>
          </ul>
        </nav>
        <button onClick={toggleUnit}>Switch to °{unit === 'C' ? 'F' : 'C'}</button>
        <AnimatedRoutes
          temperatures={temperatures}
          favorites={favorites}
          toggleFavorite={toggleFavorite}
          unit={unit}
          initialRatings={initialRatings}
        />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
