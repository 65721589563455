import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
  return (
    <footer className="app-footer">
      <div className="footer-content">
        <p>Badetemp &copy; Temperature provided from YR.</p>
        <nav>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <a href="https://www.instagram.com/badetemp/" target="_blank" rel="noopener noreferrer">
            Instagram
          </a>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;
